import axios from "axios";
import qs from'qs';
const home = {
  gameslist() {
    //4个游戏列表
    return axios.get("/api/ppy/gamecenter/games/list", {
      params: {
        page: 1,
        pageSize: 4,
        type: -2,
      },
    });
  },
  drawingprizes() {
    //4个游戏列表
    return axios.get("/api/ppy/gamecenter/drawing/prizes");
  },
  drawingtryDrawing() {
    //尝试进行抽奖
    return axios.get("/api/ppy/gamecenter/drawing/tryDrawing");
  },
  drawingdrawingResult(fluxId) {
    //获取结果
    return axios.get("/api/ppy/gamecenter/drawing/drawingResult", {
      params: {
        fluxId: fluxId,
      },
    });
  },
  acttaskInfo() {
    //每日任务信息
    return axios.get("/api/ppy/gamecenter/act/taskInfo");
  },
  actuserActiveValue() {
    //获取用户活跃值
    return axios.get("/api/ppy/gamecenter/act/userActiveValue");
  },
  actdetail(data) {
    //活跃值明细
    return axios.get("/api/ppy/gamecenter/act/detail", {
      params: data,
    });
  }, ///ranking/getRanking
  rankinggetRanking(period) {
    //获取周榜数据
    return axios.get("/api/ppy/gamecenter/ranking/getRanking", {
      params: {
        period: period,
      },
    });
  },
  rankinggetRankingData(data) {
    //获取周榜数据
    return axios.get("/api/ppy/gamecenter/ranking/getRankingData", {
      params:data,
    });
  },//
  getRankingHistory(data) {
    //获取周榜数据
    return axios.get("/api/ppy/gamecenter/ranking/getRankingHistory", {
      params:data,
    });
  },
  rankingReward(rankId) {
    //领奖
    return axios.get("/api/ppy/gamecenter/ranking/rankingReward", {
      params:{
        rankId:rankId
      },
    });
  },
  toComplete(data) {//签到
    //领奖
    return axios.get("/api/ppy/gamecenter/act/toComplete", {
      params:data,
    });
  },//
  sgameAward(data) {//小游戏领奖
    return axios.get("/api/ppy/gamecenter/games/sgameAward", {
      params:data,
    });
  },
  personalTaskAward(data) {//小游戏领奖
    return axios.get("/api/ppy/gamecenter/games/personalTaskAward", {
      params:data,
    });
  },
  findResults(taskId) {//查询完成结果
    //领奖
    return axios.get("/api/ppy/gamecenter/act/findResults", {
      params:{
        taskId:taskId
      },
    });
  },
  adInfoFront(data) {//查询完成结果
    //领奖
    return axios.get("/api/ppy/gamecenter/games/adInfoFront", {
      params:data
    });
  },
  myInviteLink() {//查询完成结果
    //领奖
    return axios.get("/api/ppy/usercenter/invite/myInviteLink");
  },
  takeTaskAward(taskId){
    return axios.post(
      "/api/ppy/usercenter/invite/takeTaskAward",
      qs.stringify({type:1 ,taskId:taskId })
    );
  }
};
export default home;
