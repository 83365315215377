<template>
  <div class="content">
    <div class="list"  v-if="list.length>0">
      <div class="listli" v-for="(item, index) in list" :key="index">
        <div class="head">{{ item.period }}周排行</div>
        <div class="listlibottom">
          <div>
            <p>{{ item.money }}</p>
            <p>排名第{{ item.rank }}名</p>
          </div>
          <p
            v-if="item.awardStatus == 0"
            class="lingq"
            @click="lingqulist(item.rankid)"
          >
            领取
          </p>
          <p v-if="item.awardStatus == 1" class="yijlingq">已领取</p>
          <p v-if="item.awardStatus == -1" class="yijlingq">待审核</p>
        </div>
      </div>
    </div>
    <p class="bottomlist" v-if="list.length>0">～ 没有更多信息了 ～</p>
    <div class="kong" v-if="list.length==0">
      <img src="https://ppyos.xijiuyou.com/210915/fish-2.jpg" alt="" />
      <p>排名奖励为空</p>
      <p>快去赚取活跃值赢得你的奖励吧</p>
    </div>
  </div>
</template>
<script>
import home from "../api/home";
import { Toast } from "vant";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      page: 1,
      pageSize: 10,
      list: [],
      listlength: true,
    };
  },
  mounted() {
    const That = this;
    That.actdetail();
    window.addEventListener("scroll", () => {
      var ScrollTop, ClientHeight, ScrollHeight;
      ScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      ClientHeight = document.documentElement.clientHeight;
      ScrollHeight = document.documentElement.scrollHeight;
      if (ScrollHeight - ScrollTop - ClientHeight == 0 && That.listlength) {
        this.page++;
        That.actdetail();
      }
    });
  },
  methods: {
    actdetail() {
      const data = {
        page: this.page,
        pageSize: this.pageSize,
      };
      home.getRankingHistory(data).then((res) => {
        if (res.data.list.length != 10) {
          this.listlength = false;
        }
        this.list.push(...res.data.list);
      });
    },
    lingqulist(res) {
      home.rankingReward(res).then(() => {
        Toast("领取成功");
        this.list = [];
        this.actdetail();
      });
    },
  },
};
</script>
<style scoped>
.list {
  padding-top: 8px;
}
.listli {
  width: calc(100vw - 30px);
  height: 70px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding-top: 6px;
  margin-bottom: 9px;
}
.listli .head {
  width: 205px;
  height: 21px;
  background: #ffefe1;
  border-radius: 0 10px 10px 0;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ff9308;
  text-align: center;
  line-height: 21px;
  margin-bottom: 7px;
}
.listli .listlibottom {
  display: flex;
  justify-content: space-between;
  padding: 0 9px;
  align-items: center;
}
.listlibottom div {
  display: flex;
  align-items: center;
}
.listlibottom div p {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.listlibottom div p:nth-child(2) {
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-left: 19px;
}
.bottomlist {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
  margin-top: 30px;
}
.lingq {
  width: 73px;
  height: 25px;
  background: linear-gradient(0deg, #ffbb38, #ff9435);
  border-radius: 12px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.yijlingq {
  width: 73px;
  height: 25px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-align: center;
}
.kong {
  text-align: center;
}
.kong img {
  margin-top: 115px;
  width: 123px;
  height: 98px;
  margin-bottom: 24px;
}
.kong p {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
}
</style>

